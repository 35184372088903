
import {
  computed,
  defineComponent,
  inject,
  onBeforeUpdate,
  onMounted,
  PropType,
  reactive,
  ref,
  Ref,
  toRefs,
  watch,
} from "vue";
import {
  MessageOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons-vue";
import Slots from "../components/Slots.vue";
import Tags from "@hd2/common/src/components/Tags.vue";
import dayjs, { Dayjs } from "dayjs";
import { asyncForEach } from "@hd2/common/src/utils";
import { useStore } from "../store";
import { useI18n } from "vue-i18n";
import { RuleObject } from "ant-design-vue/es/form/interface";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import { each, find } from "lodash";
import { useRouter } from "vue-router";
import { Tag } from "@hd2/common/types";
import {
  AppointmentType,
  City,
  CommunicationTypes,
  Country,
  Language,
  MakeAppointmentData,
  MakeAppointmentDataExt,
  MakeAppointmentModel,
  MakeAppointmentPatient,
  Patient,
  PatientAgeRange,
  PatientType,
  RuleObjectExt,
  SlotModel,
  Specialization,
} from "../../types";
import { DefaultOptionType } from "ant-design-vue/lib/vc-select/Select";
import {
  actions,
  slotsConfig,
  blockedSlotsForSpecializations,
} from "../utils/const";
import { usePermissions } from "../composable/usePermissions";
import { Form } from "ant-design-vue";
import ChangeDeclarationFacilityPopup from "../components/ChangeDeclarationFacilityPopup.vue";

const useForm = Form.useForm;

interface LoadingStatus {
  status: string;

  cancel(): void;
}

interface PatientData {
  model: MakeAppointmentPatient;
  rules: Record<string, Array<RuleObjectExt>>;
}

export const MakeAppointmentComponent = defineComponent({
  props: {
    data: {
      type: Object as PropType<MakeAppointmentDataExt>,
      default: (): MakeAppointmentDataExt => {
        return {
          date: "",
          slotIndex: -1,
          intraRegulationAgreement: true,
          marketingAgreement: false,
          tradeAgreement: false,
          languageId: null,
          cityId: null,
          countryId: null,
          specializationId: null,
          communicationTypes: ["CALL"],
          patients: [
            {
              type: "ADULT",
              age: "",
            },
          ],
          type: "CALL",
        };
      },
    },
    nfzChoosen: {
      type: Boolean || undefined,
      default: (): boolean => false,
    },
  },
  components: {
    Slots,
    Tags,
    PlusCircleOutlined,
    ChangeDeclarationFacilityPopup,
    PhoneOutlined,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const { hasPermission } = usePermissions();
    const changeDeclarationFacilityPopupVisible =
      store.state.popupState.changeDeclarationFacility;

    const appType = store.state.runtimeConfig.type;

    const showNfzPopup = ref(false);
    const isNfzChoosen = ref(
      props.nfzChoosen
        ? props.nfzChoosen
        : store.getters.isForNfz
        ? store.getters.isForNfz
        : false
    );

    const model: MakeAppointmentModel = reactive({
      date: dayjs(),
      slotIndex: -1,
      intraRegulationAgreement: false,
      marketingAgreement: false,
      tradeAgreement: false,
      languageId: null,
      cityId: null,
      countryId: null,
      specializationId: null,
      communicationTypes: new Set(),
    });
    const type: Ref<AppointmentType> = ref("CALL");
    const slots: Ref<Array<SlotModel>> = ref([]);
    const specializationOptions: Ref<Array<Specialization>> = ref([]);
    const languageOptions: Ref<Array<Language>> = ref([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patientsFormTemplate: Ref<Array<any>> = ref([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patientsForm: Ref<Array<any>> = ref([]);
    const cityOptions: Ref<Array<City>> = ref([]);
    const countryOptions: Ref<Array<Country>> = ref([]);
    const patientAgeRangeOptions: Ref<Array<PatientAgeRange>> = ref([
      {
        label: `0-1 ${t("PATIENT.AGE_RANGE_TYPE.MONTHS")}`,
        value: "0:0.1",
      },
      {
        label: `2-3 ${t("PATIENT.AGE_RANGE_TYPE.MONTHS")}`,
        value: "0.2:0.3",
      },
      {
        label: `3-4 ${t("PATIENT.AGE_RANGE_TYPE.MONTHS")}`,
        value: "0.3:0.4",
      },
      {
        label: `5-6 ${t("PATIENT.AGE_RANGE_TYPE.MONTHS")}`,
        value: "0.5:0.6",
      },
      {
        label: `7-8 ${t("PATIENT.AGE_RANGE_TYPE.MONTHS")}`,
        value: "0.7:0.8",
      },
      {
        label: `9-12 ${t("PATIENT.AGE_RANGE_TYPE.MONTHS")}`,
        value: "0.9:1",
      },
      {
        label: `1-2 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "1:2",
      },
      {
        label: `3-4 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "3:4",
      },
      {
        label: `5-6 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "5:6",
      },
      {
        label: `7-8 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "7:8",
      },
      {
        label: `9-10 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "9:10",
      },
      {
        label: `11-12 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "11:12",
      },
      {
        label: `13-14 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "13:14",
      },
      {
        label: `15-16 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "15:16",
      },
      {
        label: `17-18 ${t("PATIENT.AGE_RANGE_TYPE.YEARS")}`,
        value: "17:18",
      },
    ]);
    const patientTypeOptions: Ref<Array<PatientType>> = ref<Array<PatientType>>(
      ["ADULT", "CHILD"]
    );

    const valid: Ref<Array<boolean>> = ref([false, true]);
    const patients: Ref<Array<PatientData>> = ref([]);
    const loading: Ref<boolean> = ref(false);
    const selectedCommunicationType: Ref<CommunicationTypes> = ref("CALL");

    const communicationTypes: Ref<Array<Tag>> = ref([]);
    if (hasPermission(actions.SHOW_COMMUNICATION_TYPE_CALL)) {
      communicationTypes.value.push({
        id: "CALL",
        name: t(`COMMUNICATION_TYPES.CALL`),
        icon: PhoneOutlined,
        disabled: false,
      });
    }
    if (
      hasPermission(actions.SHOW_COMMUNICATION_TYPE_CHAT) &&
      !isNfzChoosen.value
    ) {
      communicationTypes.value.push({
        id: "CHAT",
        name: t(`COMMUNICATION_TYPES.CHAT`),
        icon: MessageOutlined,
        disabled: false,
      });
    }
    if (
      hasPermission(actions.SHOW_COMMUNICATION_TYPE_VIDEO) &&
      !isNfzChoosen.value
    ) {
      communicationTypes.value.push({
        id: "VIDEO",
        name: t(`COMMUNICATION_TYPES.VIDEO`),
        icon: VideoCameraOutlined,
        disabled: false,
      });
    }

    const rules: Record<string, Array<RuleObjectExt>> = reactive({
      cityId: [
        {
          validator: (
            rule: RuleObject,
            value: MakeAppointmentModel["cityId"]
          ) => {
            return new Promise((resolve, reject) => {
              if (type.value === "HOUSE" && !value) {
                reject(t("MAKE_APPOINTMENT.CITY_REQUIRED"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      specializationId: [
        {
          type: "number",
          required: true,
          message: t("MAKE_APPOINTMENT.SPECIALIZATION_REQUIRED"),
          trigger: "change",
        },
      ],
      communicationTypes: [
        {
          validator: (
            rule: RuleObjectExt,
            value: MakeAppointmentModel["communicationTypes"]
          ) => {
            return new Promise((resolve, reject) => {
              if (type.value === "CALL" && value.size === 0) {
                reject(t("MAKE_APPOINTMENT.COMMUNICATION_TYPE_REQUIRED"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      date: [
        {
          type: "date",
          required: true,
          message: t("MAKE_APPOINTMENT.DATE_REQUIRED"),
          trigger: "change",
        },
      ],
      slotIndex: [
        {
          validator: (
            rule: RuleObjectExt,
            value: MakeAppointmentModel["slotIndex"]
          ) => {
            return new Promise((resolve, reject) => {
              if (value < 0) {
                reject(t("MAKE_APPOINTMENT.SLOT_REQUIRED"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      intraRegulationAgreement: [
        {
          validator: (
            rule: RuleObjectExt,
            value: MakeAppointmentModel["intraRegulationAgreement"]
          ) => {
            return new Promise((resolve, reject) => {
              if (type.value === "CALL" && !value) {
                document
                  .querySelector(".agreement-validation")
                  ?.classList.add("agreement-validation__error");
                reject(
                  t("MAKE_APPOINTMENT.INTRA_REGULATION_AGREEMENT_REQUIRED")
                );
              } else {
                document
                  .querySelector(".agreement-validation")
                  ?.classList.remove("agreement-validation__error");
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const loadingSpecializations: LoadingStatus = reactive({
      status: "",
      cancel: () => true,
    });
    const loadingCities: LoadingStatus = reactive({
      status: "",
      cancel: () => true,
    });
    const loadingCountries: LoadingStatus = reactive({
      status: "",
      cancel: () => true,
    });
    const loadingLanguages: LoadingStatus = reactive({
      status: "",
      cancel: () => true,
    });
    const loadingSlots: LoadingStatus = reactive({
      status: "",
      cancel: () => true,
    });

    const getCountries = async () => {
      if (loadingCountries.status === "pending") {
        loadingCountries.cancel();
      }

      model.countryId = null;

      loadingCountries.status = "pending";
      try {
        const countriesRes = await http.get(
          `visit-api/anonymous/countries?appointmentType=${type.value}`,
          {
            cancelToken: new http.CancelToken((c) => {
              loadingCountries.cancel = c;
            }),
          }
        );

        loadingCountries.status = "done";
        countryOptions.value = countriesRes.data;

        if (props.data.countryId) {
          model.countryId = props.data.countryId;
        } else {
          model.countryId = 616;
        }
      } catch {
        loadingCountries.status = "error";
        notification.open({
          message: t("ERROR.4393"),
          class: "error",
        });
      }
    };

    const showSlots = computed(() => {
      if (
        blockedSlotsForSpecializations
          .get(appType)
          ?.includes(model.specializationId || 0) &&
        appType === "INTER"
      ) {
        return false;
      }
      return true;
    });

    const getLanguages = async () => {
      if (loadingLanguages.cancel && loadingLanguages.status === "pending") {
        loadingLanguages.cancel();
      }

      model.languageId = null;

      loadingLanguages.status = "pending";
      try {
        const languageRes = await http.get(`visit-api/anonymous/languages`, {
          cancelToken: new http.CancelToken((c) => {
            loadingLanguages.cancel = c;
          }),
        });

        loadingLanguages.status = "done";

        languageOptions.value = languageRes.data;
        if (props.data.languageId) {
          model.languageId = props.data.languageId;
        } else {
          model.languageId = 1;
        }
      } catch {
        loadingLanguages.status = "error";
        notification.open({
          message: t("ERROR.4911"),
          class: "error",
        });
      }
    };

    const getCities = async () => {
      if (loadingCities.cancel && loadingCities.status === "pending") {
        loadingCities.cancel();
      }

      model.cityId = null;

      loadingCities.status = "pending";
      try {
        const citiesRes = await http.get(
          `visit-api/anonymous/towns?countryId=${model.countryId}`,
          {
            cancelToken: new http.CancelToken((c) => {
              loadingCities.cancel = c;
            }),
          }
        );
        loadingCities.status = "done";
        cityOptions.value = citiesRes.data;

        if (props.data.cityId) {
          model.cityId = props.data.cityId;
        } else {
          if (model.countryId == 616) {
            model.cityId = 7;
          } else {
            model.cityId = cityOptions.value[0].id;
          }
        }
      } catch {
        loadingCities.status = "error";
        notification.open({
          message: t("ERROR.4966"),
          class: "error",
        });
      }
    };

    const getSpecialization = async () => {
      if (
        loadingSpecializations.cancel &&
        loadingSpecializations.status === "pending"
      ) {
        loadingSpecializations.cancel();
      }

      model.specializationId = null;

      loadingSpecializations.status = "pending";

      try {
        const specializationsRes = await http.get(
          `visit-api/v1/specializations?type=${type.value}&city=${
            model.cityId
          }${type.value == "HOUSE" ? "&specializationType=BASIC" : ""}${
            isNfzChoosen.value ? "&forNfz=true" : "&forNfz=false"
          }&medicalPersonType=DOCTOR`,
          {
            cancelToken: new http.CancelToken((c) => {
              loadingSpecializations.cancel = c;
            }),
          }
        );
        loadingSpecializations.status = "done";
        specializationOptions.value = specializationsRes.data;

        if (props.data.specializationId) {
          model.specializationId = props.data.specializationId;
        } else {
          model.specializationId = specializationOptions.value[0].id;
        }
      } catch {
        loadingSpecializations.status = "error";
        notification.open({
          message: t("ERROR.4976"),
          class: "error",
        });
      }
    };

    const disabledPrevDate: Ref<boolean> = ref(true);

    const getSlots = async () => {
      if (loadingSlots.cancel && loadingSlots.status === "pending") {
        loadingSlots.cancel();
      }
      loadingSlots.status = "pending";

      const tmpDate = model.date.toISOString().substring(0, 10);

      disabledPrevDate.value =
        dayjs(model.date).add(-1, "days") < dayjs().startOf("day");

      model.slotIndex = -1;
      try {
        const slotIntervalsRes = await http.post(
          `visit-api/v1/slot-intervals`,
          {
            day: tmpDate,
            from: slotsConfig.from,
            to: slotsConfig.to,
            languageId: model.languageId,
            intervalSize: slotsConfig.intervalSize(
              type.value,
              model.specializationId
            ),
            appointmentType:
              type.value == "HOUSE"
                ? type.value
                : selectedCommunicationType.value,
            specializationId: model.specializationId,
            city: model.cityId
              ? find(cityOptions.value, { id: model.cityId })?.town
              : null,
            forNfz: isNfzChoosen.value ? isNfzChoosen.value : null,
          },
          {
            cancelToken: new http.CancelToken((c) => {
              loadingSlots.cancel = c;
            }),
          }
        );

        loadingSlots.status = "done";

        slots.value = [];

        for (const key in slotIntervalsRes.data.availableIntervals) {
          slots.value.push({
            from: new Date(`${tmpDate}T${key}:00.0000`),
            to: new Date(
              new Date(`${tmpDate}T${key}:00.0000`).getTime() +
                slotIntervalsRes.data.intervalSize * 60000
            ),
            disabled: !slotIntervalsRes.data.availableIntervals[key],
          });
        }
      } catch {
        loadingSlots.status = "error";
        notification.open({
          message: t("ERROR.4981"),
          class: "error",
        });
      }
    };

    const onPatientChange = (patient: PatientData, index: number) => {
      patient.model.age = "";
      patientsFormTemplate.value[index].validate("age");
    };

    const changeDate = (mode: string) => {
      switch (mode) {
        case "prev": {
          model.date = model.date.add(-1, "days");
          break;
        }
        case "next": {
          model.date = model.date.add(1, "days");
          break;
        }
      }
      disabledPrevDate.value =
        dayjs(model.date).add(-1, "days") < dayjs().startOf("day");
      getSlots();
    };

    const disabledDate = (current: Dayjs) => {
      return current && current <= dayjs().startOf("day");
    };
    const filterSpecializationOption = (
      input: string,
      option: DefaultOptionType
    ) => {
      return (
        t(
          `SPECIALIZATION.${specializationOptions.value[option.props.key].name}`
        )
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const filterCityOption = (input: string, option: DefaultOptionType) => {
      return (
        cityOptions.value[option.props.key].town
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const filterCountryOption = (input: string, option: DefaultOptionType) => {
      return (
        countryOptions.value[option.props.key].name
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const filterLanguageOption = (input: string, option: DefaultOptionType) => {
      return (
        languageOptions.value[option.props.key].name
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const addPatient = () => {
      const newPatient: PatientData = JSON.parse(
        JSON.stringify(patients.value[0])
      );
      newPatient.model = { type: "ADULT", age: "" };
      newPatient.rules = {
        age: [
          {
            options: {
              index: patients.value.length,
            },
            validator: (rule: RuleObjectExt, value: Patient["age"]) => {
              return new Promise((resolve, reject) => {
                if (
                  value === "" &&
                  patients.value[rule.options?.index].model.type === "CHILD"
                ) {
                  reject(t("MAKE_APPOINTMENT.AGE_RANGE_REQUIRED"));
                } else {
                  resolve();
                }
              });
            },
            trigger: "change",
          },
        ],
      };
      patientsForm.value.push(useForm(newPatient.model, newPatient.rules));
      patients.value.push(newPatient);
    };

    const removePatient = (index: number) => {
      patients.value.splice(index, 1);
      patientsForm.value.splice(index, 1);
    };

    const submit = async () => {
      const data: MakeAppointmentData = {
        ...model,
        patients: [],
        communicationTypes: Array.from(model.communicationTypes),
        type:
          type.value == "HOUSE" ? type.value : selectedCommunicationType.value,
      };

      let adultsCount = 0;
      let childrenCount = 0;

      if (
        data.type === "CALL" ||
        data.type === "CHAT" ||
        data.type === "VIDEO"
      ) {
        data.patients.push({ type: "ADULT", age: "" });
        adultsCount = 1;
      } else if (data.type === "HOUSE") {
        patients.value.forEach((patient) => {
          if (patient.model.type === "ADULT") {
            adultsCount++;
          } else {
            childrenCount++;
          }
          data.patients.push(patient.model);
        });
      }

      loading.value = true;
      let orderId = null;
      try {
        orderId = await http
          .post(`patient-portal/api/slot/reserve-first-available`, {
            dateTimeFrom: new Date(
              slots.value[data.slotIndex].from.getTime() -
                slots.value[data.slotIndex].from.getTimezoneOffset() * 60000
            ).toISOString(),
            dateTimeTo: new Date(
              slots.value[data.slotIndex].to.getTime() -
                slots.value[data.slotIndex].to.getTimezoneOffset() * 60000
            ).toISOString(),
            specializationId: data.specializationId,
            appointmentType: data.type,
            adultsCount: adultsCount,
            childrenCount: childrenCount,
            city: model.cityId
              ? find(cityOptions.value, { id: model.cityId })?.town
              : null,
            countryId: data.countryId,
            languageId: data.languageId,
            refreshOffset: 15,
            forNfz: isNfzChoosen.value ? isNfzChoosen.value : null,
          })
          .then((res) => res.data);
        router.push({
          name: "Order",
          params: {
            id: orderId,
          },
        });
      } catch (e: any) {
        if (e.response.status === 404) {
          router.push({
            name: "NoDoctors",
            params: {
              data: JSON.stringify(data),
            },
          });
        } else {
          loading.value = false;
          notification.open({
            message: t("ERROR.4412"),
            class: "error",
          });
        }
      }
    };

    const onChangeType = async (nv: AppointmentType, init: boolean) => {
      switch (nv) {
        case "CALL": {
          valid.value = [false, true];

          model.specializationId = null;
          model.countryId = null;
          model.languageId = null;
          model.cityId = null;
          model.slotIndex = -1;

          if (props.data.specializationId && !init) {
            model.communicationTypes = new Set(props.data.communicationTypes);
          } else {
            model.communicationTypes = new Set();
            model.communicationTypes.add(
              communicationTypes.value[0].id as CommunicationTypes
            );
          }

          loadingSlots.status = "pending";
          loadingSpecializations.status = "pending";
          loadingLanguages.status = "pending";
          await getCountries();
          await getLanguages();
          await getSpecialization();
          await getSlots();

          break;
        }
        case "HOUSE": {
          model.communicationTypes = new Set();
          model.slotIndex = -1;
          model.cityId = null;
          model.countryId = null;
          model.languageId = null;
          model.specializationId = null;
          model.intraRegulationAgreement = false;
          model.marketingAgreement = false;
          model.tradeAgreement = false;

          loadingSlots.status = "pending";
          loadingSpecializations.status = "pending";
          loadingCities.status = "pending";
          loadingLanguages.status = "pending";

          await getCountries();
          await getCities();
          await getLanguages();
          await getSpecialization();
          await getSlots();

          break;
        }
      }
    };

    const onChangeSpecialization = () => {
      if (showSlots.value) {
        getSlots();
      }
    };

    if (props.data.languageId) {
      model.communicationTypes = props.data.communicationTypes
        ? new Set(props.data.communicationTypes)
        : new Set();
      model.date = dayjs(props.data.date);
      props.data.patients.forEach((patient) => {
        patients.value.push({
          model: patient,
          rules: {
            age: [
              {
                options: {
                  index: 0,
                },
                validator: (rule: RuleObjectExt, value: Patient["age"]) => {
                  return new Promise((resolve, reject) => {
                    if (
                      !value &&
                      patients.value[rule.options?.index].model.type === "CHILD"
                    ) {
                      reject(t("MAKE_APPOINTMENT.AGE_RANGE_REQUIRED"));
                    } else {
                      resolve();
                    }
                  });
                },
                trigger: "change",
              },
            ],
          },
        });
      });
      type.value = toRefs(props.data).type.value as AppointmentType;
    } else {
      patients.value.push({
        model: { type: "ADULT" as PatientType, age: "" },
        rules: {
          age: [
            {
              options: {
                index: 0,
              },
              validator: (rule: RuleObjectExt, value: Patient["age"]) => {
                return new Promise((resolve, reject) => {
                  if (
                    value === "" &&
                    patients.value[rule.options?.index].model.type === "CHILD"
                  ) {
                    reject(t("MAKE_APPOINTMENT.AGE_RANGE_REQUIRED"));
                  } else {
                    resolve();
                  }
                });
              },
              trigger: "change",
            },
          ],
        },
      });
    }
    const changeType = (value: AppointmentType) => {
      type.value = value;
      onChangeType(value, false);
    };

    onBeforeUpdate(() => {
      patientsFormTemplate.value = [];
    });

    onMounted(async () => {
      if (props.nfzChoosen) store.commit("setForNfz", props.nfzChoosen);
      await onChangeType(type.value, true);
    });

    each(patients.value, (patient) => {
      patientsForm.value.push(useForm(patient.model, patient.rules));
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const makeAppointmentForm: any = useForm(model, rules);
    const makeAppointmentFormTemplate = ref();

    const reloadSlots = async (communicationType: Set<CommunicationTypes>) => {
      selectedCommunicationType.value = communicationType.values().next().value;
      await getSlots();
    };

    watch(
      model,
      async () => {
        try {
          await makeAppointmentForm.validate();
          valid.value[0] = true;
        } catch {
          valid.value[0] = false;
        }
      },
      { deep: true }
    );

    watch(
      patients,
      () => {
        let validCount = 0;
        asyncForEach(patientsForm.value, async (patientForm, index: number) => {
          try {
            await patientForm.validate();
            validCount++;
          } catch {
            return Promise.resolve();
          } finally {
            if (index === patients.value.length - 1) {
              if (validCount === patients.value.length) {
                valid.value[1] = true;
              } else {
                valid.value[1] = false;
              }
            }
          }
        });
      },
      { deep: true }
    );
    return {
      store,
      t,
      type,
      showSlots,
      slots,
      changeDate,
      makeAppointmentForm,
      makeAppointmentFormTemplate,
      removePatient,
      addPatient,
      valid,
      onChangeSpecialization,
      loading,
      specializationOptions,
      patientTypeOptions,
      patientAgeRangeOptions,
      cityOptions,
      communicationTypes,
      patients,
      onPatientChange,
      rules,
      model,
      loadingSpecializations,
      loadingCities,
      loadingCountries,
      loadingLanguages,
      changeType,
      loadingSlots,
      filterSpecializationOption,
      filterCityOption,
      filterCountryOption,
      filterLanguageOption,
      disabledPrevDate,
      disabledDate,
      submit,
      countryOptions,
      languageOptions,
      patientsFormTemplate,
      getSlots,
      actions,
      hasPermission,
      translationType: computed(() =>
        [""].includes(appType) ? appType : "GENERAL"
      ),
      reloadSlots,
      showNfzPopup,
      isNfzChoosen,
      changeDeclarationFacilityPopupVisible,
    };
  },
});

export default MakeAppointmentComponent;
